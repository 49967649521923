<template>
  <v-container fluid>
    <v-row
      v-scroll="checkScrollPosition"

      class="home_screen"
    >
      <!-- The title card -->
      <v-col cols="12">
        <div class="start_header">
          <div class="username_message">
            Hi {{ userData.display_name }}!
          </div>
        </div>
      </v-col>
      <!-- The list of notes -->
      <v-col
        cols="12"
        lg="6"
        sm="6"
        md="6"
      >
        <ItemList
          ref="noteItemList"
          class="note_list_home"
          title="Notes"
          :list-items="notes_buff"
          sticky-icons
          chip-images
          @itemClicked="openNoteDetails"
        />
      </v-col>
      <!-- The list of incomplete experiments -->
      <v-col
        cols="12"
        lg="6"
        sm="6"
        md="6"
      >
        <ItemList
          ref="incomItemList"
          class="exp_list_incom"
          title="Incomplete Experiments"
          :list-items="incomplete_exps_buff"
          @itemClicked="openExpDetails"
        />

        <ItemList
          ref="noaosItemList"
          class="exp_list_noaos"
          title="Experiments with no AOs"
          :list-items="noaos_exps_buff"
          @itemClicked="openExpDetails"
        />
      </v-col>
    </v-row>

    <NoteDetailDialog
      :dialog-open="noteDetailsOpen"
      :note="notes[noteDetailIndex]"
      :loading="loading"
      @closeClicked="closeNoteDetails"
      @refreshClicked="refreshData"
    />

    <ExpDetailDialog
      :dialog-open="expDetailOpen"
      :exp-i-d="expDetailID"
      @closeClicked="closeExpDetails"
    />
  </v-container>
</template>

<script>
import { EventBus } from '../scripts/event_bus'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'Home',
  components: {
    ItemList: () => import('../components/ItemList'),
    NoteDetailDialog: () => import('../components/NoteDetailDialog'),
    ExpDetailDialog: () => import('../components/ExpDetailDialog')
  },
  data () {
    return {
      notes_buff: [],
      incomplete_exps_buff: [],
      noaos_exps_buff: [],
      renderIncreaseStep: 5,
      noteDetailsOpen: false,
      noteDetailIndex: null,
      noteDetail: {},
      expDetailOpen: false,
      expDetailID: 0
    }
  },
  computed: {
    ...mapGetters({
      userData: 'userData',
      loading: 'loading',
      notes: 'welcomeNotes',
      incomplete_exps: 'welcomeIncomExps',
      noaos_exps: 'welcomeNoAOs'
    })
  },
  watch: {
    loading: function (newLoading, oldLoading) {
      if (!newLoading && oldLoading && this.$route.name === 'home') {
        // If this triggers, then there was a falling edge on loading and we are on the home page.
        this.checkScrollPosition()
      }
    }
  },
  mounted () {
    EventBus.$on('refresh', this.refreshData)
    this.refreshData()
    this.checkScrollPosition()
  },
  beforeDestroy () {
    EventBus.$off('refresh', this.refreshData)
  },
  methods: {
    ...mapActions({
      refreshData: 'refreshWelcomeData'
    }),
    checkScrollPosition: function () {
      // This is where we calculate the need to load more items to the list that is rendered!

      // First, find the new scroll position
      const scrollOffset = window.pageYOffset || document.documentElement.scrollTop

      // Now find the locations of each of the list bottoms
      const notesListBottom = this.calculateListBottom(this.$refs.noteItemList)
      const incomListBottom = this.calculateListBottom(this.$refs.incomItemList)
      const noaosListBottom = this.calculateListBottom(this.$refs.noaosItemList)

      // First do it for the notes list
      if (notesListBottom < scrollOffset && this.notes_buff.length < this.notes.length) {
        // Fill the buffer array with data from the main array, plus the extra step amount.
        this.notes_buff = this.notes.slice(0, this.notes_buff.length + this.renderIncreaseStep)
      }
      // Then for the two experiment lists
      if (incomListBottom < scrollOffset && this.incomplete_exps_buff.length < this.incomplete_exps.length) {
        // Fill the buffer array with data from the main array, plus the extra step amount.
        this.incomplete_exps_buff = this.incomplete_exps.slice(0, this.incomplete_exps_buff.length + this.renderIncreaseStep)
      }
      if (noaosListBottom < scrollOffset && this.noaos_exps_buff.length < this.noaos_exps.length) {
        // Fill the buffer array with data from the main array, plus the extra step amount.
        this.noaos_exps_buff = this.noaos_exps.slice(0, this.noaos_exps_buff.length + this.renderIncreaseStep)
      }
    },
    calculateListBottom: function (listRef) {
      if (listRef) {
        return listRef.$el.clientHeight + listRef.$el.offsetTop - window.outerHeight
      } else {
        return 0
      }
    },
    openNoteDetails: function (noteId) {
      this.noteDetailIndex = this.notes.findIndex((note) => {
        return Number(note.id) === noteId
      })

      this.noteDetailsOpen = true

      // this.refreshData()
    },
    closeNoteDetails: function () {
      this.noteDetailsOpen = false

      // TODO: Might need a refresh here
    },
    openExpDetails: function (expId) {
      this.expDetailID = expId

      this.expDetailOpen = true
    },
    closeExpDetails: function () {
      this.expDetailOpen = false
    }
  }
}
</script>

<style scoped>

</style>
